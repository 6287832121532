import ezFetch from "ez-fetch";
//import isomorphic-form-data from "isomorphic-form-data";
import axios from "axios"
import environment from "./env";
//const API_URL = "https://offerdhamaka.com/OfferDhamaka";
const API_BASE_URL = `${environment.CANADA_BACKEND_URL}/Shopiads`;
let API_URL = `${environment.CANADA_BACKEND_URL}/Shopiads`;
let API_URL_US = `${environment.USA_BACKEND_URL}/Shopiads`;
//export const API_URL = "http://34.130.141.72:8080/Shopiads";//Live pod service url
//export const API_URL = "http://localhost:9090/Shopiads"
console.log({
  environment,
});

let pathName=""
if(window!=undefined&&window.location!=undefined)
 pathName=window.location.pathname;

 console.log("path name=================",window.location.host);

if (window.location.host != undefined && window.location.host.includes(environment.CADANA_DOMAIN)) {
  API_URL = `${environment.CANADA_BACKEND_URL}/Shopiads`;
  API_URL_US = `${environment.USA_BACKEND_URL}/Shopiads`;
} else {
  API_URL = `${environment.USA_BACKEND_URL}/Shopiads`;
  API_URL_US = `${environment.CANADA_BACKEND_URL}/Shopiads`;
}

let headers = {
  "Content-Type": "application/json",
}

let Auth_api = axios.create({
  baseURL: API_URL,
  headers,
})


const storeListFields = [
  "primaryOfferImage",
  "logoMedium",
  "logo",
  "storeName",
  "totalLikes",
  "avgRating",
  "companyLogo",
  "primaryOfferImage",
  "logoSmall",
];
export default {
    bucketURL:
        "https://shopiadsimages.blob.core.windows.net/storeimages/imagesDev/",
    generateQueryString(queryParams) {
        return Object.keys(queryParams)
            .filter((key) => queryParams[key] !== null)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
    },
    encodedUserId(userId) {
        var encodedData = btoa(userId);
        return encodedData;
    },
    getCitiesAPI(userId, stateId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(`${API_URL}/cities/${encodedUserId}/${stateId}`);
    },
    getAreasAPI(userId, cityId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(`${API_URL}/areas/${encodedUserId}/${cityId}`);
    },
    getAllOffersAPI(userId, cityId, areaId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/offers/all/${encodedUserId}/${cityId}/${areaId}`
        );
    },
    getOffersByCategoryAPI(userId, categoryId, cityId, areaId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/offers/category/${encodedUserId}/${categoryId}/${cityId}/${areaId}`
        );
    },
    getAllStoresAPI(userId, cityId, areaId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/stores/all/${encodedUserId}/${cityId}/${areaId}`
        );
    },
    getAllStoresOfferAPI(userId, cityId, areaId, viewType, marketPlace) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/stores/AllActiveOffers/${encodedUserId}/${cityId}/${areaId}/${viewType}/${marketPlace}`
        );
    },
    getNearByStoresAPI(
        userId,
        cityId,
        cityName,
        areaId,
        viewType,
        marketPlace,
        pageId,
        recordsPerPage
    ) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/stores/nearByStores/${encodedUserId}/${cityId}/${cityName}/${areaId}/${viewType}/${marketPlace}/${pageId}/${recordsPerPage}?fields=${storeListFields.join(
                ","
            )}&includeField=true`
        );
    },
    getInStoresAPI(
        userId,
        cityId,
        cityName,
        areaId,
        viewType,
        marketPlace,
        pageId,
        recordsPerPage
    ) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/stores/InStores/${encodedUserId}/${cityId}/${cityName}/${areaId}/${viewType}/${marketPlace}/${pageId}/${recordsPerPage}?fields=${storeListFields.join(
                ","
            )}&includeField=true`
        );
    },
    getOnLineStoresAPI(
        userId,
        cityId,
        cityName,
        areaId,
        viewType,
        marketPlace
    ) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/stores/OnLineStores/${encodedUserId}/${cityId}/${cityName}/${areaId}/${viewType}/${marketPlace}`
        );
    },
    getAllStoresProductAPI(
        userId,
        cityId,
        areaId,
        viewType,
        marketPlace,
        pageNo
    ) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/stores/AllActiveProducts/${encodedUserId}/${cityId}/${areaId}/${viewType}/${marketPlace}/${pageNo}`
        );
    },
    getStoresProductByCategoryAPINew(query) {
        return ezFetch.get(
            `${API_URL}/stores/category/activeProductsNew?${query}`
        );
    },

    productByCategoryNew(queryParams) {
        return ezFetch.get(
            `${API_URL}/products/categoriesNew?${this.generateQueryString(
                queryParams
            )}`
        );
    },
    getAllStoresProductAPINew(query) {
        return ezFetch.get(`${API_URL}/stores/AllActiveProductsNew?${query}`);
    },
    getStoresByCategoryAPI(userId, categoryId, cityId, areaId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/stores/category/${encodedUserId}/${categoryId}/${cityId}/${areaId}`
        );
    },

    getStoresOfferByCategoryAPI(
        userId,
        categoryId,
        cityId,
        stateId,
        viewTpe,
        marketPlace
    ) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/stores/category/activeOffers/${encodedUserId}/${categoryId}/${cityId}/${stateId}/${viewTpe}/${marketPlace}`
        );
    },

    getStoresProductByCategoryAPI(
        userId,
        categoryId,
        cityId,
        stateId,
        viewTpe,
        marketPlace,
        pageNo
    ) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/stores/category/activeProducts/${encodedUserId}/${categoryId}/${cityId}/${stateId}/${viewTpe}/${marketPlace}/${pageNo}`
        );
    },

    getActiveSectionItem(
        userId,
        categoryId,
        cityId,
        stateId,
        viewTpe,
        marketPlace
    ) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/stores/AllActiveSectionItem/${encodedUserId}/${categoryId}/${cityId}/${stateId}/${viewTpe}/${marketPlace}`
        );
    },
    getActiveSectionItemByPage(
        userId,
        catId,
        cityId,
        stateId,
        viewType,
        marketPlace,
        pageNumber,
        recordsPerPage
    ) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/offers/AllActiveSectionItemNew?${this.generateQueryString(
                {
                    user: encodedUserId,
                    catId,
                    city: cityId,
                    state: stateId,
                    viewType,
                    marketPlace,
                    pageNumber: pageNumber ?? 1,
                    recordsPerPage,
                }
            )}`
        );
    },
    getProductsByOfferSection(
        catId,
        city,
        section,
        state,
        viewType,
        marketPlace,
        pageNumber,
        recordsPerPage
    ) {
        var encodedUserId = this.encodedUserId("guest");
        return ezFetch.get(
            `${API_URL}/products/getProductsByOfferSection?${this.generateQueryString(
                {
                    user: encodedUserId,
                    catId,
                    city,
                    section,
                    state,
                    viewType,
                    marketPlace,
                    pageNumber: pageNumber ?? 1,
                    recordsPerPage,
                }
            )}`
        );
    },
    getOffersByOfferSection(
        catId,
        city,
        section,
        state,
        viewType,
        marketPlace,
        pageNumber,
        recordsPerPage
    ) {
        var encodedUserId = this.encodedUserId("guest");
        return ezFetch.get(
            `${API_URL}/offers/getOffersByOfferSection?${this.generateQueryString(
                {
                    user: encodedUserId,
                    catId,
                    city,
                    section,
                    state,
                    viewType,
                    marketPlace,
                    pageNumber: pageNumber ?? 1,
                    recordsPerPage,
                }
            )}`
        );
    },

    // To get category by id
    getMainCategories(userId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(`${API_URL}/mainCategoriesList/${encodedUserId}`);
    },

    // To get category by id
    getCategoriesById(userId, id) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/getCategoriesById/${encodedUserId}/${id}`
        );
    },

    async getCategoriesByIdAsync(userId, id) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/getCategoriesById/${encodedUserId}/${id}`
        );
    },

    getAllSubCategories(userId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(`${API_URL}/allsubCategoriesList/${encodedUserId}`);
    },

    subCategoriesListByName(userId, name) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/subCategoriesListByName/${encodedUserId}/${name}`
        );
    },

    subCategoriesListByMainCat(userId, id) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/subCategoriesList/${encodedUserId}/${id}`
        );
    },

    productByCategory(userId, catId, city, marketPlace, pageId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/products/categories/${encodedUserId}/${catId}/${city}/${marketPlace}/${pageId}`
        );
    },

    productSameStore(branchId, status) {
        return ezFetch.get(
            `${API_URL}/getCompanyBranchProduct/${branchId}/${status}`
        );
    },

    getAllState(userId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(`${API_URL}/states/${encodedUserId}`);
    },

    OffersBYStore(id) {
        return ezFetch.get(`${API_URL}/stores/OffersBYStore/${id}`);
    },

    CollectionBYStore(id) {
        return ezFetch.get(`${API_URL}/stores/CollectionBYStore/${id}`);
    },

    getOfferProducts(
        offerId,
        pageId,
        recordPerPage,
        orderBy = true,
        asc = true
    ) {
        const searchQuery = this.generateQueryString({
            offerId,
            pageId,
            recordPerPage,
            orderBy,
            asc,
        });
        return ezFetch.get(`${API_URL}/getOfferProducts?${searchQuery}`);
    },

    productByStore(id, pageId, recordPerPage, orderBy, asc) {
        const searchQuery = this.generateQueryString({
            id,
            pageId,
            recordPerPage,
            orderBy,
            asc,
        });
        return ezFetch.get(`${API_URL}/stores/productByStore?${searchQuery}`);
    },

    getOffersById(id, offerId) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.get(
            `${API_URL}/getOffersById/${encodedUserId}/${offerId}`
        );
    },

    makeLikes(userId, id, type) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/offers/makeLikes/${encodedUserId}/${id}/${type}`
        );
    },

    makeUnLikes(userId, id, type, snipId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/offers/makeUnLikes/${encodedUserId}/${id}/${type}/${snipId}`
        );
    },

    followStore(userId, id, type) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/offers/makeFollow/${encodedUserId}/${id}/${type}`
        );
    },

    productBuyNow(userId, id) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/productBuyNowClick/${encodedUserId}/${id}`
        );
    },

    offerBuyNow(userId, id) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/offerBuyNowClick/${encodedUserId}/${id}`
        );
    },

    productById(userId, id) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(`${API_URL}/products/${encodedUserId}/${id}`);
    },

    markUserDelete(userId) {
        //var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(`${API_URL}/markUserDelete/${userId}`);
    },

    userDelete(userId) {
        //var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(`${API_URL}/unRegisterUser/${userId}`);
    },

    userDeleteUS(userId) {
        //var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(`${API_URL_US}/unRegisterUser/${userId}`);
    },

    getStoreById(id) {
        return ezFetch.get(`${API_URL}/stores/getStoreById/${id}`);
    },

    isUserShopifyOrder(userId, id) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/isUserShopifyOrder/${encodedUserId}/${id}`
        );
    },

    getAllStoreList(marketPlace) {
        return ezFetch.get(
            `${API_URL}/stores/getAllStoreList/${marketPlace}?fields=${storeListFields.join(
                ","
            )}&includeField=true`
        );
    },

    getCompanyId(id) {
        return ezFetch.get(`${API_URL}/getCompanyById/${id}`);
    },

    getVariantProducts(id) {
        return ezFetch.get(`${API_URL}/variantProducts/${id}`);
    },

    getVariantProductsStock(id) {
        return ezFetch.get(`${API_URL}/checkVariantStock/${id}`);
    },

    addSnip(id, data) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.post(`${API_URL}/snippets/add/${encodedUserId}`, data);
    },

    deleteSnip(id, data) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.post(
            `${API_URL}/snippets/delete/${encodedUserId}`,
            data
        );
    },

    deleteSnips(id, data) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.post(
            `${API_URL}/snippets/deletes/${encodedUserId}`,
            data
        );
    },

    getUserSnips(id, type) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.get(
            `${API_URL}/getUserSnippets/${encodedUserId}/${type}`
        );
    },

    getRatingByProduct(id) {
        return ezFetch.get(`${API_URL}/ratingByProductId/${id}`);
    },

    getRatingByOffer(id) {
        return ezFetch.get(`${API_URL}/ratingByOfferId/${id}`);
    },

    getUserOfferRating(id) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.get(`${API_URL}/getUserOfferRating/${encodedUserId}`);
    },

    getUserProductRating(id) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.get(`${API_URL}/getUserProductRating/${encodedUserId}`);
    },

    createUser(id, data) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.post(`${API_URL}/users/${encodedUserId}`, data);
    },

    createUserUS(id, data) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.post(`${API_URL_US}/users/${encodedUserId}`, data);
    },

    updateWebUser(id, data) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.post(`${API_URL}/updateWebUser/${encodedUserId}`, data);
    },

    addContactUs(id, data) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.post(`${API_URL}/contactUs/${encodedUserId}`, data);
    },

    getUserById(id) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.get(`${API_URL}/getUserById/${encodedUserId}`);
    },

    getUserByEncodingId(id) {
        return ezFetch.get(`${API_URL}/getUserById/${id}`);
    },

    getUserByEncodingIdUS(id) {
        return ezFetch.get(`${API_URL_US}/getUserById/${id}`);
    },

    async updateUserSignIn(id) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.get(`${API_URL}/updateUserSignIn/${encodedUserId}`);
    },

    getProductSearch(query) {
        return ezFetch.get(`${API_URL}/stores/searchProductNew?${query}`);
    },

    productAutoSearch(text, catId, marketPlace) {
        return ezFetch.get(
            `${API_URL}/stores/productAutoSearch/${text}/${catId}/${marketPlace}`
        );
    },

    async getProductSearchAsync(
        text,
        catId,
        pageId,
        marketPlace,
        subCatId,
        brand,
        rate,
        fromPrice,
        toPrice
    ) {
        return ezFetch.get(
            `${API_URL}/stores/searchProductNew?text=${text}&catId=${catId}&subCatId=${subCatId}&brand=${brand}&rate=${rate}&fromPrice=${fromPrice}&toPrice=${toPrice}&pageId=${pageId}&marketPlace=${marketPlace}`
        );
    },

    getStoreFollow(userId, type) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/getUserSnippets/${encodedUserId}/${type}`
        );
    },

    getStoreFollowList(storeId) {
        return ezFetch.get(`${API_URL}/getStoreFollowList/${storeId}`);
    },

    updateUserWebToken(userId, token) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/updateUserWebToken/${encodedUserId}/${token}`
        );
    },

    saveUserNotification(id, data) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.post(
            `${API_URL}/saveUserNotification/${encodedUserId}`,
            data
        );
    },

    getUserNotification(userId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(`${API_URL}/getUserNotification/${encodedUserId}`);
    },

    deleteUserNotification(userId) {
        var encodedUserId = this.encodedUserId(userId);
        return ezFetch.get(
            `${API_URL}/deleteUserNotification/${encodedUserId}`
        );
    },

    readFileDataAsBase64(e) {
        const file = e.target.files[0];

        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                resolve(event.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            return reader.readAsDataURL(file);
        });
    },

    /*  uploadBillImage(file,companyName,storeId,billId) {
      require('isomorphic-form-data');
      var fs = require('fs');
      console.log("file data in api>>>>>>>>>>>",file);
      const formData = new FormData()
      formData.append("file", fs.createReadStream(file.name))
      console.log("formData data in api>>>>>>>>>>>",formData);
      const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' } };
      return ezFetch.post(
        `${API_URL}/uploadBillImage/${companyName}/${storeId}/${billId}`, formData,config
      );
    },*/

    async uploadBillImage(formData, companyName, storeId, billId) {
        try {
            // let response = await axios.post(`${API_URL}/uploadImage/${storeId}/${imageName}`, formData)
            let response = Auth_api.post(
                `/uploadBillImage/${companyName}/${storeId}/${billId}`,
                formData
            );
            // store.state.logoUrl = bucketURL.concat(response.data)
            return response;
        } catch (e) {
            this.errors.push(e);
        }
    },

    async uploadRatingImage(formData, companyName, storeId, billId) {
        try {
            // let response = await axios.post(`${API_URL}/uploadImage/${storeId}/${imageName}`, formData)
            let response = Auth_api.post(
                `/uploadRatingImage/${companyName}/${storeId}/${billId}`,
                formData
            );
            // store.state.logoUrl = bucketURL.concat(response.data)
            return response;
        } catch (e) {
            this.errors.push(e);
        }
    },

    addBill(id, data) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.post(`${API_URL}/bills/${encodedUserId}`, data);
    },

    addRating(id, data) {
        var encodedUserId = this.encodedUserId(id);
        return ezFetch.post(`${API_URL}/rating/${encodedUserId}`, data);
    },
    searchStore(data) {
        return ezFetch.get(
            `${API_URL}/stores/searchStore?text=${data.text}&catId=${data.catId}&marketPlace=${data.marketPlace}`
        );
    },

    async unRegisterUser(email) {
        var encodedUserId = this.encodedUserId(email);
        try {
            let response = Auth_api.get(`/unRegisterUser/${encodedUserId}`);
            return response;
        } catch (e) {
            this.errors.push(e);
        }
    },
    async getShopifyShopToken(weburl) {
        return ezFetch.get(`${API_BASE_URL}/getShopifyShopToken/${weburl}`);
    },
    sendUserDeleteMail(email) {
        var encodedUserId = this.encodedUserId(email);
        try {
            let response = Auth_api.get(
                `/stores/sendUserDeleteMail/${encodedUserId}`
            );
            return response;
        } catch (e) {
            this.errors.push(e);
        }
    },
    getSectionById: async (id) => {
        return ezFetch.get(`${API_URL}/getSectionById/${id}`);
    },
};
